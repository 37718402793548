<template>
  <div class="button-flex-container">
    <!-- Back button -->
    <button
      v-if="step > 1"
      type="button"
      class="button white justify-start"
      @click.prevent="$emit('changeStep', step - 1)"
    >
      Tilbage
    </button>

    <!-- Button step 1 -->
    <template v-for="(button, index) in steps">
      <button
        v-if="step == index + 1"
        :key="index"
        type="submit"
        class="button"
        :form="'step' + (index + 1)"
        :disabled="button.loading || button.disabled || disableButton"
      >
        <template v-if="button.loading"><i class="fa-light fa-spinner-third fa-fw icon-spin" /></template>
        <template v-else-if="steps.length == index + 1 && betaling">Gennemfør bestilling</template>
        <template v-else-if="steps.length == index + 1 && !existingPolicy">Tilføj til indkøbskurv</template>
        <template v-else-if="steps.length == index + 1 && existingPolicy">Opdater indkøbskurv</template>
        <template v-else>Næste</template>
      </button>
    </template>
  </div>
</template>

<script>
export default {
  name: "ProgressButtons",
  props: {
    step: {
      type: Number,
      default: null,
    },
    steps: {
      type: Array,
      default: new Array(),
    },
    existingPolicy: {
      type: Boolean,
      default: false,
    },
    betaling: {
      type: Boolean,
      default: false,
    },
    disableButton: {
      type: Boolean,
      default: false
    }
  },
};
</script>
