<template>
  <div>
    <div class="box">
      <h4>Billeddokumentation af din {{ product.fields.vehicleCrmField.value }}</h4>
      <p>
        Når du vil tegne en veteranbilforsikring hos os, skal du sende os tre
        billeder af den bil, du ønsker at forsikre. Du skal tage et billede
        <b>skråt forfra</b>, <b>skråt bagfra</b> og <b>af motorrummet</b>. Du
        kan herunder se eksempler på, hvordan du tager billederne korrekt.
        <br />
        <br />
        <a
          href="./documents/Veteranbil-fotoguide-Aros-Forsikring.pdf"
          target="_blank"
          >Se eksempel på hvordan du tager billederne</a
        >
      </p>
      <br />
      <label
        :for="`fileUpload${$vnode.key}`"
        class="dropzone"
        ref="dropzone"
        :class="[
          { 'files-added': product.documents.length },
          { disabled: disabled },
          { 'dropzone-error': error },
        ]"
        @dragover.prevent="dragover"
        @dragleave.prevent="dragleave"
        @drop.prevent="onFileChange"
      >
        <template v-if="!error">
          <div
            v-for="(file, index) in product.documents"
            :key="index"
            class="dropzone-file"
            @click.prevent
          >
            <i class="fa-light fa-paperclip-vertical dropzone-file-icon"></i>
            <span>{{ file.ContentName }}</span>
            <button type="button" class="button red delete-button" @click.prevent="removeImage(index)">Fjern fil</button>
          </div>
          <span
            v-if="!disabled"
            class="dropzone-button button"
            :class="[{ white: product.documents.length }]"
          >
            <template v-if="product.documents.length">Tilføj flere filer</template>
            <template v-else>Tilføj filer</template>
          </span>
        </template>
        <span v-if="error && typeof error == 'string'" class="dropzone-error-text">{{ error }}</span>
      </label>
      <input
        type="file"
        class="dropzone-file-upload"
        :id="`fileUpload${$vnode.key}`"
        @change.prevent="onFileChange"
        multiple
      />
      <FormInput
        :data="checkboxWithRequired"
        @formFunction="formFunction"
        class="margin-top-20"
      />
    </div>
    <i v-if="!lastElement" class="fa-light fa-plus margin-bottom-20 text-align-center display-block"></i>
  </div>
</template>

<script>
import FormInput from "@/components/FormInput.vue";

export default {
  components: { FormInput },
  name: "Documentation",
  props: {
    product: {
      type: Object,
      default: null,
    },
    lastElement: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileLimit: 10,
      sizeLimit: 100,
      disabled: false,
      error: null,
      errorDuration: 5000,
      isDrag: false,

      // Checkbox field
      checkbox: {
        label: "Jeg ønsker at sende billeddokumentationen på et senere tidspunkt",
        property: "consent",
        type: "checkbox",
        value: null,
        error: false,
        onInput: "checkNoDocumentation",
        required: true,
        onInvalid: "showError",
      },
    };
  },
  computed: {
    // If the file
    isCheckboxRequired() {
      return this.product.documents.length == 0;
    },
    // Checkbox field with dynamic required field
    checkboxWithRequired() {
      let field = this.checkbox;
      field.required = this.isCheckboxRequired;
      return field;
    },
  },
  watch: {
    error(value) {
      if (value) {
        setTimeout(() => {
          this.error = null;
        }, this.errorDuration);
      }
    },
  },
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;

      // If files is empty, show error
      if (!files.length) {
        return;
      }

      // If number of files is over limit
      if (files.length > this.fileLimit || files.length + this.product.documents.length > this.fileLimit) {
        this.error = `Du kan maksimalt tilføje ${this.fileLimit} filer`;
        return;
      }

      for (var i = 0; i < files.length; i++) {
        this.createFile(files[i]);
      }
    },
    createFile(file) {
      // If file is too large, show error
      if (file.size / 1024 / 1024 > this.sizeLimit) {
        this.error = `Filen "${file.name}" er for stor. Vi godtager filer på maksimum ${this.sizeLimit} MB`;
        return;
      }

      // Define file reader
      var reader = new FileReader();

      // Function for when the reader has processed file
      reader.onload = () => {
        let newFile = {
          ContentName: file.name,
          ContentFilename: file.name,
          ContentType: file.type,
          Content: reader.result.split(",")[1],
        };

        this.product.documents.push(newFile);
      };

      // Read file from input
      reader.readAsDataURL(file);
    },
    removeImage(index) {
      this.product.documents.splice(index, 1);
    },
    dropFile(e) {
      this.onFileChange(e);
    },
    dragover() {
      this.isDrag = true;
    },
    dragleave() {
      this.isDrag = false;
    },
    checkNoDocumentation(value) {
      this.product.documents = [];

      if (value) {
        this.disabled = true;
      } else {
        this.disabled = false;
      }
    },
    // Show error from checkbox invalid
    showError() {
      this.error = "Tilføj dokumentation for at fortsætte bestillingen";
    },
  },
};
</script>
