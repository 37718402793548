<template>
  <div>
    <div class="box">
      <h4>Din hunds chipnummer</h4>
      <p>
        Vi skal bruge din hunds chipnummer, for at vi kan tegne en
        hundeforsikring til dig. Du er dog velkommen til at oplyse det på et
        senere tidspunkt.
      </p>
      <FormInput class="input" :data="inputObj" :product="product" />
      <FormInput :data="checkObj" :product="product" />
    </div>
  </div>
</template>

<script>
import FormInput from "./FormInput.vue";

export default {
  components: {
    FormInput,
  },
  data() {
    return {
      inputObj: {
        property: "chipTatoNr",
        type: "text",
        value: "",
        error: "",
        maxlength: 41,
        label: "Chipnummer",
        validationMessage: "Du skal udfylde chipnummer på din hund eller vælge at du vil oplyse dette på et andet tidspunkt",
        required: true,
      },
      checkObj: {
        type: "checkbox",
        label: "Jeg ønsker at oplyse min hunds chipnummer på et senere tidspunkt",
        value: false,
      },
    };
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  watch: {
    inputObj: {
      handler(n) {
        this.product.fields.chipTatoNr.value = n.value;
      },
      deep: true,
    },
    checkObj: {
      handler(n) {
        this.inputObj.required = typeof n.value == "undefined" || n.value === null || n.value === false;
        if (!this.inputObj.required) this.inputObj.value = "";
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.input {
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}
</style>
