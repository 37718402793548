<template>
  <div>
    <h4 class="progress-indicator-mobile-text">{{ currentStepName }}</h4>
    <ul class="progress-indicator">
      <li
        v-for="(item, index) in steps"
        :key="index"
        :class="[
          { active: step == index + 1 },
          { completed: step > index + 1 },
        ]"
      >
        <div class="bubble">
          <div class="bubbleIcon">
            <i v-if="step > index + 1" class="fa-light fa-check"></i>
            <i v-else class="fa-light" :class="item.icon"></i>
          </div>
        </div>
        <span class="text">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProgressIndicator",
  props: {
    step: {
      type: Number,
      default: null,
    },
    steps: {
      type: Array,
      default: new Array(),
    },
  },
  computed: {
    /*
     * Get the name of current step
     */
    currentStepName() {
      let stepName = "Ukendt";

      this.steps.forEach((element, index) => {
        if (index + 1 == this.step) {
          stepName = element.name;
        }
      });

      this.$emit("stepName", stepName);

      return stepName;
    },
  },
};
</script>
