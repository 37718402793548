<template>
  <div>
    <div class="container small">
      <h1 class="page-headline">Gennemfør bestilling</h1>
      <progressIndicator :step="step" :steps="betaling.steps" />
    </div>
    <template v-if="currentStep.name == 'Tidligere selskab'">
      <div class="container small">
        <div class="box">
          <form id="step1" v-on:submit.prevent="submitStep1">
            <!-- Existing customer -->
            <FormInput
              :data="betaling.fields.existingCustomer"
              :product="betaling"
              v-on:formFunction="formFunction"
            />

            <!-- Existing policies -->
            <FormInput
              :data="betaling.fields.hasExistingPolicies"
              :product="betaling"
              v-on:formFunction="formFunction"
            />

            <!-- Former company -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="betaling.fields.hasExistingPolicies.value == 'true'"
                :data="betaling.fields.formerCompany"
                :product="betaling"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Cancellation choice -->
            <transition name="hideFormGroup">
              <FormInput
                v-show="
                  betaling.fields.hasExistingPolicies.value == 'false' ||
                  (betaling.fields.hasExistingPolicies.value == 'true' &&
                    betaling.fields.formerCompany.value)
                "
                :data="betaling.fields.cancellationChoice"
                :product="betaling"
                v-on:formFunction="formFunction"
              />
            </transition>

            <!-- Cancellation Date -->
            <transition name="hideFormGroup">
              <FormInput
                v-if="betaling.fields.cancellationChoice.value == 'otherDate'"
                :data="betaling.fields.fromDate"
                :product="betaling"
                v-on:formFunction="formFunction"
              />
            </transition>
          </form>
        </div>
      </div>
    </template>
    <template v-else-if="currentStep.name == 'Dokumentation'">
      <div class="container small">
        <form
          :id="`step${betaling.steps.indexOf(currentStep) + 1}`"
          @submit.prevent="submitDocumentation"
        >
          <Documentation
            v-for="(product, index) in productsWithDocumentUpload"
            :key="index"
            :product="product"
            :lastElement="index == productsWithDocumentUpload.length - 1"
          />
          <ChipInfo v-for="(product, index) in productsWithChipInfo" :key="index" :product="product" />
        </form>
      </div>
    </template>
    <template v-else-if="currentStep.name == 'Kontaktoplysninger'">
      <div class="container small">
        <div class="box">
          <form
            :id="`step${betaling.steps.indexOf(currentStep) + 1}`"
            v-on:submit.prevent="submitStep2"
          >
            <template v-for="field in betaling.fields">
              <FormInput
                v-if="field.step == 2"
                :key="field.property"
                :data="field"
                :product="betaling"
                v-on:formFunction="formFunction"
              />
            </template>
            <a
              href="https://aros-forsikring.dk/om-os/generel-information/behandling-af-persondata/"
              target="_blank"
              >Læs Aros Forsikrings persondatapolitik</a
            >
          </form>
        </div>

        <!-- Fejlbesked -->
        <message
          v-show="error.step2"
          :message="error.step2"
          type="warning"
          v-on:formFunction="formFunction"
        />
      </div>
    </template>
    <div class="container small">
      <!-- Buttons on bottom -->
      <ProgressButtons
        :step="step"
        :steps="betaling.steps"
        :betaling="true"
        @changeStep="changeStep"
      />
    </div>
  </div>
</template>

<script>
import betalingsForm from "@/assets/json/betaling.json";
import progressIndicator from "@/components/progress-indicator.vue";
import ProgressButtons from "@/components/ProgressButtons.vue";
import FormInput from "@/components/FormInput.vue";
import message from "@/components/message.vue";
import debounce from "lodash/debounce";
import Documentation from "@/components/Documentation.vue";
import ChipInfo from "../components/ChipInfo.vue";

export default {
  name: "Betaling",
  components: {
    progressIndicator,
    FormInput,
    ProgressButtons,
    message,
    Documentation,
    ChipInfo
},
  data() {
    return {
      betaling: this.copy(betalingsForm),
      step: 1,
      loading: {
        step1: false,
        step2: false,
      },
      error: {
        step1: null,
        step2: null,
      },
      validation: {
        error: null,
        step: null,
      },
    };
  },
  computed: {
    /*
     * Basket from vuex store
     */
    basket() {
      return this.$store.state.basket;
    },
    /*
     * Coupon from vuex store
     */
    coupon() {
      return this.$store.state.coupon;
    },
    /*
     * Get products with document upload function
     */
    productsWithDocumentUpload() {
      return this.basket.filter((product) => product.enableDocumentUpload);
    },
    /*
     * Get products with extra info function
     */
    productsWithChipInfo() {
      return this.basket.filter((product) => product.enableChipInfo);
    },
    /*
     * Get current step
     */
    currentStep() {
      return this.betaling.steps[this.step - 1];
    },
    /*
     * Number of collect discount products
     */
    collectDiscountNumber() {
      let number = 0;

      // Count every collect discount product
      this.basket.forEach((element) => {
        if (element.enableCollectDiscount) {
          number++;
        }
      });

      return number;
    },
    /*
     * Boolean for setting collect discount
     */
    collectDiscount() {
      return this.collectDiscountNumber > 2;
    },
    /*
     * Age from products
     */
    age() {
      let age = null;

      this.basket.forEach((element) => {
        if (element.fields.age.value) {
          age = element.fields.age.value;
        }
      });

      return age;
    },
    /*
     * Check requirements for step 1
     */
    requirementsStep1Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.betaling.fields)) {
        if (value.required && value.step == 1) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Check requirements for step 2
     */
    requirementsStep2Met() {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.betaling.fields)) {
        if (value.required && value.step == 2) {
          if (!value.value || value.error) {
            return false;
          }
        }
      }
      return true;
    },
    /*
     * Termination dates
     */
    terminationDates() {
      // Variable for termination dates
      let terminationDates = [];

      // Dayjs variable for adding to array
      let today = this.dayjs().date(1).add(1, "month");

      // Number of months to be able to choose
      let numberOfMonths = 12;

      for (let i = 0; i < numberOfMonths; i++) {
        // Variable for current month
        let month = this.dayjs(this.copy(today.add(i, "month")));

        // Variable to push to termination dates
        let terminationDate = {
          key: month.format("YYYY-MM-DD"),
          value: month.format("DD. MMMM YYYY"),
        };

        // Push to list
        terminationDates.push(terminationDate);
      }

      return terminationDates;
    },
  },
  created() {
    // Redirect if basket is empty
    if (!this.basket || this.basket.length == 0) {
      this.$router.push({ name: "Hjem" });
    }

    // If we have document upload elements
    if (this.productsWithDocumentUpload.length || this.productsWithChipInfo.length) {
      this.betaling.steps.splice(1, 0, {
        name: "Dokumentation",
        icon: this.productsWithDocumentUpload.length ? "fa-file-import" : "fa-memo",
        loading: false,
      });
    }

    // Track data for retargeting
    this.trackData("checkoutStart", null, this.basket);
  },
  watch: {
    step: {
      handler: function (newValue, oldValue) {
        this.scrollToTop();
        // If you change step, update router with push,. otherwise just update router with replace, so that navigation is intact
        if (oldValue && newValue != oldValue) {
          // Update query with new step value
          let query = this.copy(this.$route.query);
          query.step = newValue;

          // Push value to router
          this.$router.push({ name: "Betaling", query: query }).catch(() => {});
        } else if (!oldValue && newValue != this.$route.query.step) {
          // Update query with new step value
          let query = this.copy(this.$route.query);
          query.step = newValue;

          // Push value to router
          this.$router.replace({
            name: "Betaling",
            query: query,
          });
        }
      },
      immediate: true,
    },
    "$route.query.step": {
      handler(value) {
        if (value) {
          this.step = parseInt(value);
        }
      },
      immediate: true,
    },
  },
  methods: {
    /*
     * Existing policies function
     */
    hasExistingPolicies() {
      // Remove former company
      this.betaling.fields.formerCompany.value = null;

      // Remove cancellation choice
      this.betaling.fields.cancellationChoice.value = null;

      // If existing policies
      if (this.betaling.fields.hasExistingPolicies.value == "true") {
        // Set former company to required
        this.betaling.fields.formerCompany.required = true;

        // Change requires for cancellation choice
        this.betaling.fields.cancellationChoice.requires = [
          "hasExistingPolicies",
          "formerCompany",
        ];

        // Change text on cancellation choice
        this.betaling.fields.cancellationChoice.label =
          "Hvornår skal vi opsige dine nuværende forsikringer?";
        this.betaling.fields.cancellationChoice.description =
          "Du kan benytte dig af kort opsigelsesvarsel, som betyder, at du kan opsige din nuværende forsikring med 30 dages varsel til udgangen af en kalendermåned. Det koster dig normalt et gebyr pr. forsikring. Har du haft din forsikring i mindre end 1 år, kan det være, at du vil blive opkrævet et forhøjet gebyr. Tjek med dit nuværende forsikringsselskab, hvad der gælder for din forsikring.";

        // Set cancellation date to month and add minimum date
        this.betaling.fields.fromDate.type = "Select";
        this.betaling.fields.fromDate.options = this.terminationDates;

        // Change label and description on cancellation date
        this.betaling.fields.fromDate.label =
          "Hvornår skal dine forsikringer gælde fra?";
      } else if (this.betaling.fields.hasExistingPolicies.value == "false") {
        // Set former company to not required
        this.betaling.fields.formerCompany.required = false;

        // Change requires for cancellation choice
        this.betaling.fields.cancellationChoice.requires = [
          "hasExistingPolicies",
        ];

        // Change text on cancellation choice
        this.betaling.fields.cancellationChoice.label =
          "Hvornår skal dine forsikringer træde i kraft?";
        this.betaling.fields.cancellationChoice.description = null;

        // Set cancellation date to date and add minimum date
        this.betaling.fields.fromDate.type = "date";
        this.betaling.fields.fromDate.min = this.dayjs().format("YYYY-MM-DD");
        this.betaling.fields.fromDate.max = this.dayjs()
          .add(1, "year")
          .format("YYYY-MM-DD");

        // Change label and description on cancellation date
        this.betaling.fields.fromDate.label =
          "Hvilken dato skal dine forsikringer gælde fra?";
      }
    },
    /*
     * Existing policies function
     */
    cancellationChoice() {
      // Reset cancellation date
      if (this.betaling.fields.hasExistingPolicies.value == "true") {
        this.betaling.fields.fromDate.value = this.dayjs()
          .date(1)
          .add(1, "month")
          .format("YYYY-MM-DD");
      } else if (this.betaling.fields.hasExistingPolicies.value == "false") {
        this.betaling.fields.fromDate.value = this.dayjs().format("YYYY-MM-DD");
      }

      // If existing policies
      if (this.betaling.fields.cancellationChoice.value == "immediately") {
        // Set cancellation date to not required
        this.betaling.fields.fromDate.required = false;
      } else if (this.betaling.fields.cancellationChoice.value == "otherDate") {
        // Set cancellation date to required
        this.betaling.fields.fromDate.required = true;
      }
    },
    /*
     * CPR number validation
     */
    cprCvr() {
      if (
        this.betaling.fields.cprCvr.value &&
        this.betaling.fields.cprCvr.value.length == 10
      ) {
        this.checkCpr();
      }
    },
    /*
     * Function for changing step
     */
    changeStep(step) {
      this.step = step;
    },
    /*
     * Check cpr number
     */
    checkCpr: debounce(function () {
      if (!this.checkModulus11(this.betaling.fields.cprCvr.value)) {
        this.betaling.fields.cprCvr.error =
          this.betaling.fields.cprCvr.validationMessage;
      }
    }, 2000),
    /*
     * When you select dawa option
     */
    setAddress(option) {
      this.betaling.fields.customerAddress.error = null;
      this.betaling.fields.fullAddress.value = option.tekst;
      this.betaling.fields.street.value = option.adresse.vejnavn;
      this.betaling.fields.streetNumber.value = option.adresse.husnr;
      this.betaling.fields.postalCode.value = option.adresse.postnr;
      this.betaling.fields.postalCity.value = option.adresse.postnrnavn;
      this.betaling.fields.floor.value = option.adresse.etage;
      this.betaling.fields.entrance.value = option.adresse.dør;
    },
    /*
     * Reset address fields
     */
    resetAddress() {
      this.betaling.fields.customerAddress.error = null;
      this.betaling.fields.customerAddress.selected = null;
      this.betaling.fields.fullAddress.value = null;
      this.betaling.fields.street.value = null;
      this.betaling.fields.streetNumber.value = null;
      this.betaling.fields.postalCode.value = null;
      this.betaling.fields.postalCity.value = null;
      this.betaling.fields.floor.value = null;
      this.betaling.fields.entrance.value = null;
    },
    /*
     * Parse data to api
     */
    parseSubmitData() {
      let self = this;
      //let reduction = {};

      // Create Submit data object with standard baseInfo
      const desc = self.getProductDescription(self.basket);    
      let submitData = {
        customer: {
          age: self.age,
        },
        termination: new Object(),
        policies: {
          carObjectData: [],
          homeObjectData: [],
          personalAccidentObjectData: [],
          buildingObjectData: [],
          caravanObjectData: [],
          dogObjectData: [],
          travelObjectData: [],
          baseInfo: {
            age: self.age,
            voucher: self.coupon,
            collectDiscount: self.collectDiscount,
          },
        },
        productDescription: desc,
      };

      // Run through fields and fill out data
      for (const [key, value] of Object.entries(self.betaling.fields)) {
        // Only send if the field is set to export and set into specific object
        if (value.export) {
          submitData[value.export][key] = value.format
            ? self[value.format](value.value)
            : value.value;
        }
      }

      // Loop through packages
      // eslint-disable-next-line no-unused-vars
      self.basket.forEach((element, index) => {
        const pval = element;

        // Create car object for the array in submitData
        let currentObject = {
          key: index,
          coverages:
            "coveragesBase" in element.package
              ? this.$options.filters.renderTextInObject(
                  Array.from(element.package.coveragesBase),
                  element
                )
              : [],
        };

        // Run through fields and fill out data
        for (const [key, value] of Object.entries(element.fields)) {
          // Only send if the field is set to export
          if (value.export) {
            currentObject[key] = value.value;
          }
        }

        // Run through coverages and put in car object
        element.package.coverages.forEach((coverage) => {
          /// Only send if package is included and set to export
          if (coverage.included && coverage.export) {
            // Create coverage element
            let currentCoverage = {
              bundleCode: coverage.key,
              excessId: element.fields.excessId.value,
            };

            // Inject values
            if ("inject" in coverage) {
              for (const key in coverage.inject) {
                currentCoverage[key] = element.fields[coverage.inject[key]].value;
              }
            }

            // Push this coverage to car object
            currentObject.coverages.push(currentCoverage);
          }
        });

        // Run through extra coverages and put in coverages
        if (element.coverages) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(element.coverages)) {
            // Only send if coverage is chosen
            if (value.chosen) {
              const keys = Array.isArray(value.keys) ? value.keys : value.keys[pval.package.price.key];

              // Run through keys on extra coverage
              keys.forEach((number) => {
                // Create coverage element
                let currentCoverage = {
                  bundleCode: number,
                  excessId: element.fields.excessId.value,
                };

                // Inject values
                if ("inject" in value) {
                  for (const key in value.inject) {
                    currentCoverage[key] =
                      element.fields[value.inject[key]].value;
                  }
                }

                // Push this coverage to car object
                currentObject.coverages.push(currentCoverage);
              });
            }
          }
        }

        if (element.documents && element.documents.length) {
          currentObject.files = element.documents;
        }

        // Map if priceobject is 'personalAccidentObjectData'
        if (element.priceObject == "personalAccidentObjectData") {
          currentObject.coverages = currentObject.coverages.map((cov) => {
            if ([200, 250].includes(parseInt(cov.bundleCode))) {
              cov.bundleSumInsured = parseInt(currentObject.bundleSumInsured);
            }

            if ([100].includes(parseInt(cov.bundleCode))) {
              cov.bundleSumInsured = parseInt(currentObject.deathSumInsured);
            }

            return cov;
          });
        }

        // Push to submit data
        submitData.policies[element.priceObject].push(currentObject);

        // Add extra products
        if ("extra" in element) {
          for (const key in element.extra) {
            const extra = element.extra[key];

            if (extra.chosen) {
              let prod = { ...extra.product, key: `extra_${key}` };

              // imports from main product
              if ("import" in extra) {
                for (const imp of extra.import) {
                  prod[imp] = currentObject[imp];
                }
              }

              // Add prod
              submitData.policies[element.priceObject].push(prod);
            }
          }
        }

        // Add extra packages
        /*for (const [k, p] of Object.entries(element.packages)) {
          const sk = `${element.productCode}-${k}`;
          const n = reduction[sk] || 0; 

          if (p.extra && !(p.singleton && n >= 1)) {
            submitData.policies[element.priceObject].push({ ...p, key: k });
            reduction[sk] = sk in reduction ? reduction[sk] + 1 :  1;
          }
        }*/
      });

      return submitData;
    },
    /*
     * Event when you choose a package
     */
    finishOrder() {
      let self = this;

      // Set loading to true
      self.$store.commit("loadingTrue");

      // Get submit data without coverages
      let submitData = self.parseSubmitData();     
      // Console log if in dev, test or beta environment
      if (
        process.env.NODE_ENV == "development" ||
        process.env.NODE_ENV == "test" ||
        process.env.NODE_ENV == "beta"
      ) {
        console.log(submitData);
      }

      // Create encrypted data
      let encryptedData = JSON.stringify(
        self.encrypt(JSON.stringify(submitData))
      );

      // Create api string
      let apiString = `/api/order`;

      self
        .axios({
          method: "post",
          url: apiString,
          headers: {
            "Content-Type": "application/json",
            "x-ws-key": process.env.VUE_APP_KEY,
            Authorization: `Bearer ${self.$store.state.token}`,
          },
          data: encryptedData,
        })
        .then(function (response) {
          // Track data for retargeting
          self.trackData("checkoutEnd", null, self.basket);

          // Send to finish site
          self.$router.push({
            name: "Ordre",
            params: {
              ordre: response.data,
            },
          });

          self.$store.commit("emptyBasket");

          // Set loading to false
          self.$store.commit("loadingFalse");
        });
    },
    /*
     * Go to message box
     */
    messageBox() {
      const { firstName, lastName, email, telephoneMobile, fullAddress, street, streetNumber, postalCity, postalCode, floor, entrance } = this.betaling.fields;
      this.$router.push({
        name: "Henvendelse",
        params: {
          validation: '',//this.validation,
          firstname: firstName.value,
          lastname: lastName.value,
          mail: email.value,
          telephone: telephoneMobile.value,
          adresse: fullAddress.value,
          Street: street.value,
          StreetNumber: streetNumber.value,
          City: postalCity.value,
          PostalCode: postalCode.value,
          Floor: floor.value,
          Entrance: entrance.value,
        },
      });
    },
    /*
     * Submit step 1 in form
     */
    submitStep1() {
      let self = this;

      // Set step 2
      self.step = 2;
    },
    /*
     * Submit step 2 in form
     */
    submitStep2() {
      // Reset errors
      this.error.step2 = null;

      // Check age
      const age = this.$options.filters.cprToAge(
        this.betaling.fields.cprCvr.value
      );

      if (age < 18) {
        this.error.step2 = {
          headline: "Vi kan desværre ikke beregne en pris på dine forsikringer",
          text: "Da du er under 18 år, kan vi ikke give dig en pris på dine forsikringer. Du er velkommen til at kontakte os, hvis du har brug for at få uddybet, hvorfor din alder har betydning for, at du kan tegne forsikringer hos os.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      // Check RKI
      if (this.betaling.fields.rki.value) {
        this.error.step2 = {
          headline: "Vi kan desværre ikke færdiggøre bestillingen",
          text: "Vi kan desværre ikke færdiggøre din bestilling. Udfyld kontaktformularen og vores kundeservice kontakter dig herom.",
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };

        return;
      }

      this.finishOrder();
    },
    /*
     * Submit event for documentation
     */
    submitDocumentation() {
      this.step = 3;
    },
    testStep2() {
      let self = this;

      self.error.step2 = null;

      if (!self.requirementsStep2Met) {
        self.error.step2 = {
          headline:
            "Du skal udfylde alle felter for at gennemføre din bestilling online",
          text: 'Ønsker du ikke at udfylde CPR-nummer, navn, adresse, telefonnummer og email, kan du trykke på "Kontakt mig" og oprette en henvendelse til os. Så vil vi kontakte dig telefonisk med et tilbud.',
          button: {
            text: "Kontakt mig",
            function: "messageBox",
          },
        };
      }
    },
  },
};
</script>
